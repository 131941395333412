
import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class Organigrama extends Vue {
  private mediaUrl = process.env.VUE_APP_MEDIA_URL;
  public showDialog = false;
  public dialogInfo: any = {};
  public areaData = [
    {
      id: "1",
      title: "ALCALDE: Tomás Vodanovic",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>El alcalde es la máxima autoridad de la Municipalidad y en tal calidad le corresponderá su dirección y administración superior y la supervigilancia de su funcionamiento.</p>

<p>
</p>

<p>Facultades, funciones y atribuciones del Alcalde</p>

<p>
</p>

<p>a) Representar judicial y extrajudicialmente a la municipalidad.</p>

<p>
</p>

<p>b) Proponer al concejo la organización interna de la municipalidad.</p>

<p>
</p>

<p>c) Nombrar y remover a los funcionarios de su dependencia de acuerdo con las normas estatutarias que los rijan.</p>

<p>
</p>

<p>d) Velar por la observancia del principio de la probidad administrativa dentro del municipio y aplicar medidas disciplinarias al personal de su dependencia, en conformidad con las normas estatutarias que lo rijan.</p>

<p>
</p>

<p>e) Administrar los recursos financieros de la municipalidad, de acuerdo con las normas sobre administración financiera del Estado <i>...</i><a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693"><i>Ver más Art. 56, 63, 64, 65, 67 y 68 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p>
</p>

<p>Podrá ordenar, a petición del Director de Obras Municipales, la demolición de los edificios existentes y el desalojo de sus ocupantes con el auxilio de la fuerza pública, con cargo al propietario, sin perjuicio de las demás sanciones que procedan, en aquellas propiedades que no cumplan con las disposiciones del plan regulador, ...<i><a target="_blank" href="http://www.leychile.cl/Navegar?idNorma=13560&amp;idParte=&amp;idVersion" >Ver más Art. 123 y 124 Ley General de</a><a target="_blank" href="http://www.leychile.cl/Navegar?idNorma=13560&amp;idParte=&amp;idVersion" > Urbanismo y Construcciones.</a></i></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 3 del Reglamento Interno de la Municipalidad de Maipú N° 1794</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias</i></a><i>.</i></p>
`,
      popUp: true,
      link: "",
      target: "_blank",
      x: "688",
      y: "240",
      width: "250",
      height: "63"
    },
    {
      id: "2",
      title: "CONCEJO MUNICIPAL",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>Al Concejo le corresponderá:</p>

<p>
</p>

<p>a) Elegir al alcalde, en caso de vacancia, de acuerdo con lo dispuesto en el artículo 62. Para este efecto el concejal deberá acreditar y cumplir con los requisitos especificados en el inciso segundo del artículo 57.</p>

<p>
</p>

<p>b) Pronunciarse sobre las materias que enumera el artículo 65 de esta ley.</p>

<p>
</p>

<p>c) Fiscalizar el cumplimiento de los planes y programas de inversión municipales y la ejecución del presupuesto municipal y analizar el registro público mensual de gastos detallados que lleva la Dirección de Administración y Finanzas... <i>Ver más Art. </i><a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>79</i></a><i>, 80, 81 y 87 Ley N°18.695 Orgánica Constitucional de<a target="_blank" href="http://www.leychile.cl/Navegar?idNorma=251693" > Municipalidades</a>.</i></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "460",
      y: "245",
      width: "170",
      height: "76"
    },
    {
      id: "3",
      title: "CONSEJO COMUNAL DE ORGANIZACIONES DE LA SOCIEDAD CIVIL",
      imagen: "",
      description: `
      <p>Al Consejo le corresponderá:</p>

<p>a) Pronunciarse, en el mes de marzo de cada año, sobre:</p>

<p>1. La cuenta pública que el Alcalde de su gestión anual y de la marcha general de la Municipalidad, según lo dispuesto en el artículo 64 de la Ley N° 18.695. Orgánica Constitucional de Municipalidades.</p>

<p>2. La cobertura y eficiencia de los servicios municipales, y</p>

<p>3. Las Materias que hayan sido establecidas por el Consejo;</p>

<p>b. Formular observaciones a los informes que el Alcalde le presentara sobre los presupuestos de inversión, plan comunal de desarrollo y modificaciones al plan regulador, disponiendo para ello de quince días hábiles;..<a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/6700_Reglamento_COSOC_.pdf" ><i>Ver más Art. N° 26, 27, 28 y 29 del Reglamento del Consejo Comunal de Organizaciones de la Sociedad Civil de la Comuna de Maipú N° 6700.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "224",
      y: "242",
      width: "175",
      height: "80"
    },
    {
      id: "4",
      title: "JUZGADOS",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>PRIMER JUZGADO DE POLICÍA LOCAL – Carla Torres Aguayo</p>

<p>SEGUNDO JUZGADO DE POLICÍA LOCAL – Luis Rojas Lagos</p>

<p>TERCERO JUZGADO DE POLICÍA LOCAL – Jacqueline Garrido Guajardo</p>

<p>
</p>

<p>Artículo 14°: Los Juzgados de Policía Local dependen administrativamente de la Municipalidad y el Juez, disciplinariamente de la Corte de Apelaciones respectiva. Los Juzgados de Policía Local tienen por objeto administrar justicia en el territorio jurisdiccional que corresponde a la comuna de Providencia, de acuerdo a la competencia que les otorga la Ley... <a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 5 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=12193&amp;idVersion=2021-08-13" ><i>...Ver más Art. 13 y 14 Decreto 307 Fija el texto refundido, coordinado y sistematizado de la Ley N°15.231, Sobre Organización y Atribuciones de los Juzgados de Policía Local</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "1030",
      y: "345",
      width: "175",
      height: "74"
    },
    {
      id: "5",
      title: "GABINETE DE ALCALDÍA: Carlos Pizarro",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>Tendrá por objetivo apoyar el desarrollo de las actividades administrativas y protocolares del Alcalde…<a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 8, 9 y 10 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "525",
      y: "440",
      width: "170",
      height: "48"
    },
    {
      id: "6",
      title: "SECRETARIA MUNICIPAL: Ricardo Henríquez Valdés",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>La Secretaría Municipal estará a cargo de un Secretario Municipal que tendrá las siguientes funciones: </p>

<p>
</p>

<p>a) Dirigir las actividades de secretaría administrativa del alcalde y del concejo.</p>

<p>
</p>

<p>b) Desempeñarse como ministro de fe en todas las actuaciones municipales...<a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i> Ver más Art. 20 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 11 y 12 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "525",
      y: "501",
      width: "170",
      height: "48"
    },
    {
      id: "7",
      title: "ADMINISTRACIÓN MUNICIPAL: Jorge Córdova Obreque",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>El Administrador Municipal tiene por objeto ser el colaborador directo del Alcalde en las tareas de coordinación y gestión permanente del municipio y en la elaboración y seguimiento del plan anual de acción municipal y ejercerá las atribuciones que señale este Reglamento Municipal y las que le delegue el alcalde, siempre que estén vinculadas con la naturaleza de su cargo.<a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" >..<i>Ver más Art. 30 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 7 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "525",
      y: "562",
      width: "170",
      height: "48"
    },
    {
      id: "8",
      title: "DIRECCIÓN DE ADMINISTRACIÓN Y FINANZAS: Ernesto Torres Carrazana",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) Asesorar al alcalde en la administración del personal de la municipalidad. </p>

<p>b) Asesorar al alcalde en la administración financiera de los bienes municipales, para lo cual le corresponderá específicamente:</p>

<p>1.- Estudiar, calcular, proponer y regular la percepción de cualquier tipo de ingresos municipales.</p>

<p>2.- Colaborar con la Secretaría Comunal de Planificación en la elaboración del presupuesto municipal.</p>

<p>3.- Visar los decretos de pago... <a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 27 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 28, 29 y 30 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "87",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "9",
      title: "DIRECCIÓN DE ASESORÍA JURÍDICA: Fabián Farías",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>Le corresponderá prestar apoyo en materias legales al alcalde y al concejo. Además, informará en derecho todos los asuntos legales que las distintas unidades municipales le planteen, las orientará periódicamente respecto de las disposiciones legales y reglamentarias y mantendrá al día los títulos de los bienes municipales...<a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 28 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 32, 33 y 34 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "270",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "10",
      title: "DIRECCIÓN DE CONTROL: María Elisa Baschmann Henríquez",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) Realizar la auditoría operativa interna de la municipalidad, con el objeto de fiscalizar la legalidad de su actuación.</p>

<p>b) Controlar la ejecución financiera y presupuestaria municipal.</p>

<p>c) Representar al alcalde los actos municipales que estime ilegales, informando de ello al concejo, para cuyo objeto tendrá acceso a toda la información disponible...<a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" > <i>Ver más Art. 29 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 35 y 36 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "454",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "11",
      title: "SECRETARÍA COMUNAL DE PLANIFICACIÓN: Francisco Llanos Clavijo",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>Desempeñará funciones de asesoría del alcalde y del concejo, en materias de estudios y evaluación, propias de las competencias de ambos órganos municipales. </p>

<p>En tal carácter, le corresponderán las siguientes funciones:</p>

<p>a) Servir de secretaría técnica permanente del alcalde y del concejo en la formulación de la estrategia municipal, como asimismo de las políticas, planes, programas y proyectos de desarrollo de la comuna.</p>

<p>b) Asesorar al alcalde en la elaboración de los proyectos de plan comunal de desarrollo y de presupuesto municipal.</p>

<p>c) Evaluar el cumplimiento de los planes, programas, proyectos, inversiones y el presupuesto municipal, e informar sobre estas materias al concejo, a lo menos semestralmente... <i><a target="_blank" href="https://www.leychile.cl/Navegar?idNorma=251693" >Ver más Art. 21 Ley N°18.695 Orgánica Constitucional de</a><a target="_blank" href="https://www.leychile.cl/Navegar?idNorma=251693" > Municipalidades.</a></i></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 13, 14 y 15 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "637",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "12",
      title:
        "DIRECCIÓN DE ASEO, ORNATO Y GESTION AMBIENTAL: Paloma Valenzuela Hernández",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) El aseo de las vías públicas, parques, plazas, jardines y, en general, de los bienes nacionales de uso público existentes en la comuna.</p>

<p>b) El servicio de extracción de basura.</p>

<p>c) La construcción, conservación y administración de las áreas verdes de la comuna...<a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 25 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 22, 23 y 24 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "820",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "13",
      title: "DIRECCIÓN DE TRÁNSITO Y TRANSPORTE: Anai Muñoz Sánchez",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) Otorgar y renovar licencias para conducir vehículos.</p>

<p>b) Determinar el sentido de circulación de vehículos, en coordinación con los organismos de la Administración del Estado competentes... <a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 26 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 25, 26 y 27 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "1000",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "14",
      title: "DIRECCIÓN DE OPERACIONES: Javier Reyes",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>La Dirección de Operaciones tendrá como objetivo principal la coordinación con las distintas Unidades Municipales por la ejecución y gestión de acciones específicas en los temas referentes a: alumbrado público, pavimentación, emergencia, canales y aguas lluvias. El control de las intervenciones realizadas en los bienes nacionales de uso público, tanto el Municipio como por terceros, para asegurar su adecuada ejecución, utilización y mantención y el resguardo de los intereses de la comunidad local, en las áreas entes mencionadas... <a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 37, 38 y 39 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "1184",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "15",
      title:
        "DIRECCIÓN DE RIESGOS, DESASTRES Y EMERGENCIAS: Antonio Carvajal Cancino",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>La Dirección de Riesgos, Desastres y Emergencias tiene por función planificar, organizar y coordinar los recursos municipales en materias de prevención, mitigación, preparación, respuesta y rehabilitación referidas a emergencias, desastres y catástrofes en conformidad con los lineamientos estratégicos establecidos para tal efecto. Así mismo, deberá mantener operativo el Sistema de Telecomunicaciones de la Municipalidad y colaborar en todas aquellas tareas que la ley encarga a las Municipalidades...<a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 15 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art 56, 57 y 58 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "1368",
      y: "714",
      width: "169",
      height: "74"
    },
    {
      id: "16",
      title: "DIRECCIÓN DE INSPECCIÓN:  Vania Rammsy",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>La Dirección de Inspección, tendrá como objetivo ejecutar y/o coordinar las acciones de inspección para el cumplimiento de las normas y requisitos vigentes en el desarrollo de cualquier actividad que deba ser fiscalizada por la Municipalidad de Maipú. En este sentido, tendrá la tuición de todas las inspecciones que desarrollen las distintas unidades municipales en virtud de normas especiales. Para efectos de coordinación, dependerá del Comité de Espacios Públicos.<a target="_blank" href="http://firma.providencia.cl/dsign/cgi/main.exe/VerDocSDTree?IDDOC=875759" ><i>..</i></a><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 53, 54 y 55 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "182",
      y: "808",
      width: "169",
      height: "74"
    },
    {
      id: "17",
      title: "DIRECCIÓN DE TECNOLOGÍA Y COMUNICACIONES: Jorge Ramírez Molina",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>La Dirección de Tecnología y Comunicaciones tendrá como objetivo desarrollar y aplicar los sistemas que sean necesarios para garantizar el uso óptimo de los servidores, equipos y redes de comunicación, además del normal funcionamiento de los procesos de información. Desarrollará, difundirá y mantendrá un sistema de seguridad de la información, destinado a garantizar la continuidad de los procesos informáticos. Además, administrará y fiscalizará el uso de todos los equipos computacionales del Municipio, tanto en lo relativo a las configuraciones de hardware como de software...<a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 50, 51 y 52 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",

      x: "550",
      y: "808",
      width: "169",
      height: "74"
    },
    {
      id: "18",
      title:
        "DIRECCIÓN DE PREVENCIÓN Y SEGURIDAD CIUDADANA: Carlos Aguirre Catalán",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>La Dirección de Seguridad Ciudadana tendrá como objetivo estudiar y proponer todas las acciones y medidas que tengan por objeto mejorar las condiciones de seguridad de los vecinos de la comuna y sus bienes y llevar a cabo aquellas que la autoridad comunal disponga, sin prejuicio de las facultades propias de Carabineros de Chile...<a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art.44, 45 y 46 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "365",
      y: "808",
      width: "169",
      height: "74"
    },
    {
      id: "19",
      title: "DIRECCIÓN DE SALUD: Patricia González",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) Elaborar e implementar el plan de salud,</p>

<p>b) Diseñar, elaborar e implementar los planes, programas y proyectos en materia de salud;</p>

<p>c) Elaborar políticas y ejecutar acciones de Promoción, Prevencio0n, Diagnóstico, Tratamiento y Rehabilitación en el ámbito de Salud;</p>

<p>d) Colaborar con la autoridad Sanitaria en materia de fiscalización y cumplimiento de las disposiciones legales y reglamentarias correspondientes a la protección del medioambiente y de la salud de las personas;</p>

<p>e) Optimizar los recursos, en materia de Salud, promoviendo el trabajo en redes efectivas;</p>

<p>f) Administrar los recursos humanos, materiales y financieros, supervisando, controlando y evaluando el normal desempeño de la gestión de las unidades dependientes, en coordinación con la Dirección de Administración y Finanzas Municipal; ...<a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 48, 49 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>

</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "732",
      y: "808",
      width: "169",
      height: "74"
    },
    {
      id: "20",
      title: "DIRECCIÓN DE DESARROLLO COMUNITARIO: Felipe Fernández Soto",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) Asesorar al alcalde y, también, al concejo en la promoción del desarrollo comunitario.</p>

<p>b) Prestar asesoría técnica a las organizaciones comunitarias, fomentar su desarrollo y legalización y promover su efectiva participación en el municipio, y... <a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 22 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 16, 17y 18 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "916",
      y: "808",
      width: "169",
      height: "74"
    },
    {
      id: "21",
      title: "DIRECCIÓN DE OBRAS MUNICIPALES: Guillermo Borcoski Cebrero (S)",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>a) Velar por el cumplimiento de las disposiciones de la Ley General de Urbanismo y Construcciones, del plan regulador comunal y de las ordenanzas correspondientes, para cuyo efecto gozará de las siguientes atribuciones específicas... <a target="_blank" href="https://www.bcn.cl/leychile/navegar?idNorma=251693" ><i>Ver más Art. 24 Ley N°18.695 Orgánica Constitucional de Municipalidades.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art. 19, 20 y 21 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

<p>Podrá autorizar la ejecución de construcciones provisorias por una sola vez, hasta por un máximo de tres años, en las condiciones que determine en cada caso...<a target="_blank" href="https://www.leychile.cl/Navegar?idNorma=13560&amp;idParte=&amp;idVersion" ><i>Ver más Art. 9, 123 y 124 Ley General de Urbanismo y Construcciones.</i></a></p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "1097",
      y: "808",
      width: "169",
      height: "74"
    },
    {
      id: "22",
      title:
        "SERVICIO MUNICIPAL DE AGUA POTABLE Y ALCANTARILLADO (SMAPA): Felipe Neira",
      imagen: "",
      bajadaImagen: "",
      description: `
      <p>El Servicio Municipal de Agua Potable y Alcantarillado (SMAPA) tiene como objetivo, dentro de las área de las concesiones sanitarias de que es titular la lustre Municipal de Maipú, producir y distribuir agua potable y recolectar las aguas servi8das de todos los usuarios conectados a sus redes y tratar las aguas servidas en las concesiones que corresponda, velando porque la prestación del servicio se ajuste a las normas de calidad y continuidad exigida en la legislación sanitaria vigente...<a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/1794_Reglamento_de_Estructura_interna.pdf" ><i>Ver más Art.40, 41, 42 y 43 del Reglamento Interno de la Municipalidad de Maipú N° 1794.</i></a></p>

<p>
</p>

<p><a target="_blank" href="https://www.transparenciamaipu.cl/wp-content/uploads/2023/12/5379_incorpora_direccion_reglamento_interno_23.pdf" ><i>Decreto Alcaldicio N°5379/2022, que dispone la creación de la Dirección de Riesgo, Desastres y Emergencias.</i></a></p>

<p>
</p>

      `,
      popUp: true,
      link: "",
      target: "_blank",
      x: "1280",
      y: "808",
      width: "169",
      height: "74"
    }
  ];

  public openDialog(item) {
    if (item.popUp) {
      this.dialogInfo = item;
      this.showDialog = true;
    } else {
      window.open(item.link, item.target);
    }
  }

  public closeDialog() {
    this.showDialog = false;
    this.dialogInfo = {};
  }
}
