
import { Component, Vue } from "vue-property-decorator";
import Organigrama from "@/components/Municipalidad/Organigrama.vue";

@Component({
  components: {
    Organigrama
  }
})
export default class OrganigramaView extends Vue {}
